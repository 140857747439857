// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ENJqt{display:flex;flex-direction:column}.ENJqt .GI7Ex{display:flex;align-items:center}.ENJqt .GI7Ex .aWeeH{display:flex;border-radius:var(--wpp-border-radius-xs);width:60px;height:48px}.ENJqt .GI7Ex .ifOP0{margin:0 32px}.ENJqt .qkuLe{margin:20px 0}", "",{"version":3,"sources":["webpack://./src/pages/manage-theme/components/Colors/components/ColorsUsageDetails/ColorsUsageDetails.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,qBAAA,CAEA,cACE,YAAA,CACA,kBAAA,CAEA,qBACE,YAAA,CACA,yCAAA,CACA,UAAA,CACA,WAAA,CAGF,qBACE,aAAA,CAIJ,cACE,aAAA","sourcesContent":[".body {\n  display: flex;\n  flex-direction: column;\n\n  .header {\n    display: flex;\n    align-items: center;\n\n    .preview {\n      display: flex;\n      border-radius: var(--wpp-border-radius-xs);\n      width: 60px;\n      height: 48px;\n    }\n\n    .colorText {\n      margin: 0 32px;\n    }\n  }\n\n  .divider {\n    margin: 20px 0;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "ENJqt",
	"header": "GI7Ex",
	"preview": "aWeeH",
	"colorText": "ifOP0",
	"divider": "qkuLe"
};
export default ___CSS_LOADER_EXPORT___;
