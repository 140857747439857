import React from 'react'

import { createTheme } from '@platform-ui-kit/components-library/dist/platform-ui-kit/utils/theme'
import {
  WppActionButton,
  WppCard,
  WppExpandableCard,
  WppDivider,
  WppTooltip,
  WppIconReset,
  WppIconBookInformation,
  WppTypography,
  WppListItem,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppIconText,
} from '@platform-ui-kit/components-library-react'
import { useFormikContext } from 'formik'
import { get, isEqual, uniq } from 'lodash'

import { useAppContext } from 'app/context'
import letterSpacing from 'assets/letter-spacing.svg'
import lineHeight from 'assets/line-height.svg'
import { FormInput } from 'components/FormInput/FormInput'
import { FormSelect } from 'components/FormSelect/FormInput'
import {
  CreateTheme,
  TypographyTypeSection as ITypographyTypeSection,
  TypographyTypeSubsection as ITypographyTypeSubsection,
  FontType,
} from 'pages/manage-theme/types'
import { getThemeTypographyCreateThemeInfo } from 'pages/manage-theme/utils'

import styles from './TypographyTypeSectioin.module.scss'
import { FONT_WEIGHT_NAMES } from '../../consts'

const TYPOGRAPHY_PARAMS = ['fontSize', 'letterSpacing', 'lineHeight']

const TYPOGRAPHY_WEIGHT_OPTIONS = ['400', '500', '600']

const getFontWeightName = (weight: string | number) => FONT_WEIGHT_NAMES[String(weight)]

const TypographyTypeSubsection: React.FC<{
  fontPreview: ITypographyTypeSubsection['fontPreview']
  inputName: string
  selectedFontName: string
  selectedSecondaryFontName: string
  canHaveSecondaryFont: boolean
}> = ({ inputName, fontPreview, selectedFontName, selectedSecondaryFontName, canHaveSecondaryFont = false }) => {
  const appContext = useAppContext()
  const theme = createTheme(getThemeTypographyCreateThemeInfo(appContext) as any)

  const { customFont, secondaryCustomFont, secondaryFont, fontTypes } = appContext

  const getWeightOptions = (): (number | string)[] => {
    const selectedFontType = get(fontTypes, inputName, 'primary')

    if (selectedFontName === customFont?.name && selectedFontType === 'primary') {
      return uniq(customFont?.weights)
    }

    if (selectedSecondaryFontName === secondaryCustomFont?.name && selectedFontType === 'secondary') {
      return uniq(secondaryCustomFont?.weights)
    }

    return TYPOGRAPHY_WEIGHT_OPTIONS
  }

  const handleFontTypeChange = async (fontType: FontType) => {
    appContext.setFontTypes({
      ...appContext.fontTypes,
      [inputName]: fontType,
    })
  }

  const selectedFontType = get(fontTypes, inputName, 'primary')

  const isWithSecondaryFontControls = canHaveSecondaryFont && !!secondaryFont?.name

  return (
    <div className={styles.section}>
      <WppCard className={styles.card} variant="secondary">
        <WppTypography type={fontPreview.type} style={theme}>
          {fontPreview.text}
        </WppTypography>
      </WppCard>
      <div className={styles.actions}>
        {isWithSecondaryFontControls && (
          <WppSegmentedControl className={styles.customSegmentedControl} value={selectedFontType}>
            <WppSegmentedControlItem
              className={styles.customSegmentedControlItemLeft}
              value="primary"
              onClick={() => handleFontTypeChange('primary')}
            >
              Primary font
            </WppSegmentedControlItem>
            <WppSegmentedControlItem
              className={styles.customSegmentedControlItemRight}
              value="secondary"
              onClick={() => handleFontTypeChange('secondary')}
            >
              Secondary font
            </WppSegmentedControlItem>
          </WppSegmentedControl>
        )}
        <div className={styles.inputsWrapper}>
          <FormSelect
            name={`${inputName}.fontWeight`}
            label=""
            placeholder="Select font weight"
            className={styles.input}
          >
            {getWeightOptions().map(option => (
              <WppListItem value={option} key={option}>
                <p slot="label">
                  {getFontWeightName(option)} / {option}
                </p>
              </WppListItem>
            ))}
          </FormSelect>

          {TYPOGRAPHY_PARAMS.map(field => (
            <FormInput
              key={`${inputName}.${field}`}
              name={`${inputName}.${field}`}
              label=""
              placeholder="Enter number"
              className={styles.input}
              withPixelsNormalization
            >
              {field === 'fontSize' && (
                <WppTooltip slot="icon-start" text="Font size" config={{ offset: [0, 18] }}>
                  <WppIconText />
                </WppTooltip>
              )}
              {field === 'letterSpacing' && (
                <WppTooltip slot="icon-start" text="Letter spacing" config={{ placement: 'bottom', offset: [0, 18] }}>
                  <img src={letterSpacing} alt="letter spacing" />
                </WppTooltip>
              )}
              {field === 'lineHeight' && (
                <WppTooltip slot="icon-start" text="Line height" config={{ placement: 'bottom', offset: [0, 18] }}>
                  <img src={lineHeight} alt="line height" />
                </WppTooltip>
              )}
            </FormInput>
          ))}
        </div>
      </div>
    </div>
  )
}

export const TypographyTypeSection: React.FC<
  ITypographyTypeSection & { selectedFontName: string; selectedSecondaryFontName: string }
> = ({ title, fieldPath, sections, usageDetails, selectedFontName, selectedSecondaryFontName }) => {
  const appContext = useAppContext()
  const formik = useFormikContext<CreateTheme>()

  const isChanged = !isEqual(get(formik.values, fieldPath), get(formik.initialValues, fieldPath))

  const handleResetField = () => {
    formik.setFieldValue(fieldPath, get(formik.initialValues, fieldPath))
  }

  return (
    <WppExpandableCard header={title} size="l" className={styles.item}>
      <div slot="actions">
        {isChanged && (
          <WppTooltip text="Reset all changes" className={styles.resetBtn} onClick={handleResetField}>
            <WppActionButton variant="secondary">
              <WppIconReset slot="icon-start" />
            </WppActionButton>
          </WppTooltip>
        )}

        <WppTooltip text="See usage details">
          <WppActionButton variant="secondary" onClick={() => appContext.setUsageDetails(usageDetails)}>
            <WppIconBookInformation slot="icon-start" />
          </WppActionButton>
        </WppTooltip>
      </div>
      <div className={styles.body}>
        {sections.map(section => [
          <TypographyTypeSubsection
            fontPreview={section.fontPreview}
            key={`${fieldPath}.${section.fieldPath}`}
            inputName={`${fieldPath}.${section.fieldPath}`}
            selectedFontName={selectedFontName}
            canHaveSecondaryFont={!!section.canHaveSecondaryFont}
            selectedSecondaryFontName={selectedSecondaryFontName}
          />,
          <WppDivider className={styles.divider} key={`divider-${fieldPath}.${section.fieldPath}`} />,
        ])}
      </div>
    </WppExpandableCard>
  )
}
