// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LSHTM{width:100%}.LSHTM .Cifs2{margin-right:4px}.LSHTM .AwjTn{display:flex;align-items:center}.LSHTM .AwjTn .PyTTh{width:360px;height:80px;margin-right:20px}.LSHTM .AwjTn .PyTTh .OADe0{width:120px;height:48px;background-color:var(--wpp-white-color);border:2px solid var(--wpp-grey-color-1000)}.LSHTM .AwjTn .PyTTh::part(card){display:flex;justify-content:center;align-items:center}.LSHTM .AwjTn .v5P_p{display:flex;flex-direction:column;justify-content:space-between}.LSHTM .AwjTn .v5P_p ._AbcP{width:-moz-fit-content;width:fit-content}.LSHTM:not(:last-child){margin-bottom:8px}", "",{"version":3,"sources":["webpack://./src/pages/manage-theme/components/ObjectStyles/components/Radius/Radius.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CAEA,cACE,gBAAA,CAGF,cACE,YAAA,CACA,kBAAA,CAEA,qBACE,WAAA,CACA,WAAA,CACA,iBAAA,CAEA,4BACE,WAAA,CACA,WAAA,CACA,uCAAA,CACA,2CAAA,CAGF,iCACE,YAAA,CACA,sBAAA,CACA,kBAAA,CAIJ,qBACE,YAAA,CACA,qBAAA,CACA,6BAAA,CAEA,4BACE,sBAAA,CAAA,iBAAA,CAKN,wBACE,iBAAA","sourcesContent":[".item {\n  width: 100%;\n\n  .resetBtn {\n    margin-right: 4px;\n  }\n\n  .body {\n    display: flex;\n    align-items: center;\n\n    .internalCard {\n      width: 360px;\n      height: 80px;\n      margin-right: 20px;\n\n      .preview {\n        width: 120px;\n        height: 48px;\n        background-color: var(--wpp-white-color);\n        border: 2px solid var(--wpp-grey-color-1000);\n      }\n\n      &::part(card) {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n      }\n    }\n\n    .actions {\n      display: flex;\n      flex-direction: column;\n      justify-content: space-between;\n\n      .tag {\n        width: fit-content;\n      }\n    }\n  }\n\n  &:not(:last-child) {\n    margin-bottom: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "LSHTM",
	"resetBtn": "Cifs2",
	"body": "AwjTn",
	"internalCard": "PyTTh",
	"preview": "OADe0",
	"actions": "v5P_p",
	"tag": "_AbcP"
};
export default ___CSS_LOADER_EXPORT___;
