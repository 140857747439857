import React, { Fragment, useState } from 'react'

import { createTheme } from '@platform-ui-kit/components-library/dist/platform-ui-kit/utils/theme'
import {
  WppButton,
  WppIconCodeView,
  WppIconExportFile,
  WppIconFileCss,
  WppIconFilePdf,
  WppIconFileZip,
  WppListItem,
  WppMenuContext,
} from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'

import { useAppContext } from 'app/context'
import { useToast } from 'hooks/useToast'
import { getThemeFromCreateThemeInfo } from 'pages/manage-theme/utils'

import { exportJson, exportFile } from './utils'
import { THEME_BUILDER_API_URL } from '../../config'
import { recursiveObjectFilter } from '../../utils'

type FileType = 'pdf' | 'zip' | 'css'

const FILE_TYPE_MIMETYPE_MAPPING: Record<FileType, string> = {
  pdf: 'application/pdf',
  zip: 'application/zip',
  css: 'text/css',
}

export const ExportAsButton: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { osContext } = useOs()
  const appContext = useAppContext()
  const { showToast } = useToast()

  const handleExport = (fileType: FileType) => {
    try {
      setIsLoading(true)

      fetch(`${THEME_BUILDER_API_URL}/files-export/generate/${fileType}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          theme: getThemeFromCreateThemeInfo(appContext),
          flatTheme: createTheme(
            recursiveObjectFilter(getThemeFromCreateThemeInfo(appContext), value => !!value) as any,
          ),
          themeName: getThemeName(),
        }),
      })
        .then(response => {
          if (response.ok) {
            return response.arrayBuffer()
          } else {
            throw new Error('Failed to load file')
          }
        })
        .then(arrayBuffer => {
          setIsLoading(false)
          const uint8Array = new Uint8Array(arrayBuffer)
          const blob = new Blob([uint8Array], { type: FILE_TYPE_MIMETYPE_MAPPING[fileType] })

          exportFile({
            blob,
            fileName: getThemeName(),
          })

          showToast({
            message: 'Theme has been exported successfully!',
            type: 'success',
            duration: 4000,
          })
        })
        .catch(() => {
          setIsLoading(false)
          showToast({
            message: 'Something went wrong, please try again!',
            type: 'error',
            duration: 4000,
          })
        })
    } catch (e) {
      showToast({
        message: 'Something went wrong, please try again!',
        type: 'error',
        duration: 4000,
      })
    }
  }

  const getThemeName = () => appContext.createTheme.name || osContext.tenant.name

  const handleJsonExport = () => {
    try {
      exportJson(appContext, getThemeName())

      showToast({
        message: 'Theme has been exported successfully!',
        type: 'success',
        duration: 4000,
      })
    } catch (e) {
      showToast({
        message: 'Something went wrong, please try again!',
        type: 'error',
        duration: 4000,
      })
    }
  }

  return (
    <WppMenuContext slot="actions" listWidth="200px">
      <WppButton slot="trigger-element">
        {isLoading ? (
          <Fragment>Loading...</Fragment>
        ) : (
          <Fragment>
            Export as
            <WppIconExportFile slot="icon-start" />
          </Fragment>
        )}
      </WppButton>
      <WppListItem onWppChangeListItem={handleJsonExport}>
        <p slot="label">JSON</p>
        <WppIconCodeView slot="left" />
      </WppListItem>
      <WppListItem onWppChangeListItem={() => handleExport('css')}>
        <p slot="label">CSS</p>
        <WppIconFileCss slot="left" />
      </WppListItem>
      <WppListItem onWppChangeListItem={() => handleExport('pdf')}>
        <p slot="label">PDF</p>
        <WppIconFilePdf slot="left" />
      </WppListItem>
      <WppListItem onWppChangeListItem={() => handleExport('zip')}>
        <p slot="label">ZIP</p>
        <WppIconFileZip slot="left" />
      </WppListItem>
    </WppMenuContext>
  )
}
