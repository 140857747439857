import React from 'react'

import {
  WppActionButton,
  WppCard,
  WppMenuContext,
  WppTypography,
  WppIconMore,
  WppTag,
  WppListItem,
  WppIconEdit,
  WppIconCodeView,
  WppIconFileCss,
  WppIconFilePdf,
} from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { useNavigate } from 'react-router-dom'

import styles from './ActiveThemeCard.module.scss'

export const ActiveThemeCard: React.FC = () => {
  const navigate = useNavigate()

  const { osContext } = useOs()

  const currentDate = new Date().toLocaleString('en-us', { year: 'numeric', month: 'long', day: 'numeric' })

  const handleCardClick = () => {
    navigate('/theme/edit')
  }

  return (
    <WppCard interactive className={styles.activeThemeCard} onClick={handleCardClick}>
      <WppMenuContext
        slot="actions"
        className={styles.menuActions}
        listWidth="200px"
        onClick={e => e.stopPropagation()}
      >
        <WppActionButton variant="secondary" slot="trigger-element">
          <WppIconMore direction="horizontal" slot="icon-start" />
        </WppActionButton>
        <WppListItem onWppChangeListItem={() => navigate('/theme/edit')}>
          <p slot="label">Edit theme</p>
          <WppIconEdit slot="left" />
        </WppListItem>
        <WppListItem>
          <p slot="label">Export JSON</p>
          <WppIconCodeView slot="left" />
        </WppListItem>
        <WppListItem>
          <p slot="label">Export CSS</p>
          <WppIconFileCss slot="left" />
        </WppListItem>
        <WppListItem>
          <p slot="label">Export PDF</p>
          <WppIconFilePdf slot="left" />
        </WppListItem>
      </WppMenuContext>
      <div className={styles.body}>
        <div className={styles.colorsBlock} />
        <div className={styles.information}>
          <div className={styles.themeName}>
            <WppTypography type="l-strong">{osContext.tenant.name} Theme</WppTypography>
            <WppTag label="Active" variant="positive" className={styles.tag} />
          </div>
          <WppTypography type="xs-body" className={styles.dateInfo}>
            Last opened {currentDate}
          </WppTypography>
        </div>
      </div>
    </WppCard>
  )
}
