import React, { useEffect, useState } from 'react'

import {
  SegmentedControlChangeEventDetail,
  TabsChangeEventDetail,
  ThemeStyles,
} from '@platform-ui-kit/components-library'
import {
  WppButton,
  WppCard,
  WppDivider,
  WppIconGallery,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppSideModal,
  WppTab,
  WppTabs,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { Formik, useFormikContext } from 'formik'
import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom'

import { useAppContext } from 'app/context'
import { ExportAsButton } from 'components/ExportAsButton/ExportAsButton'
import { ColorsPreview } from 'pages/manage-theme/components/Colors/components/ColorsPreview/ColorsPreview'
import { ObjectStylesPreview } from 'pages/manage-theme/components/ObjectStyles/components/ObjectStylesPreview/ObjectStylesPreview'
import { TypographyPreview } from 'pages/manage-theme/components/Typography/components/TypographyPreview/TypographyPreview'

import { ColorsSection } from './components/Colors/ColorsSection'
import { ObjectStylesSection } from './components/ObjectStyles/ObjectStylesSection'
import { TypographySection } from './components/Typography/TypographySection'
import { MANAGE_THEME_SECTIONS, THEME_OPTIONS } from './consts'
import styles from './ManageThemePage.module.scss'
import { CreateTheme } from './types'
import { getCreateThemeInfoFromTheme, getThemeFromCreateThemeInfo } from './utils'
import { THEME_BUILDER_API_URL } from '../../config'

const UsageDetailsModal = () => {
  const appContext = useAppContext()

  const handleClose = () => appContext.setUsageDetails(null)

  return (
    <WppSideModal open={!!appContext.usageDetails} onWppSideModalClose={handleClose} size="s">
      <WppTypography type="3xl-heading" slot="header">
        Usage details
      </WppTypography>
      {appContext.usageDetails && <div slot="body">{appContext.usageDetails.content}</div>}
      <div slot="actions" className={styles.sideModalActions}>
        <WppButton variant="primary" size="m" onClick={handleClose}>
          Done
        </WppButton>
      </div>
    </WppSideModal>
  )
}

const AutoFillSavedCreateTheme = () => {
  const appContext = useAppContext()
  const formik = useFormikContext<CreateTheme>()

  useEffect(() => {
    if (appContext.savedCreateTheme) {
      formik.setValues(appContext.savedCreateTheme)
    }
    /* eslint-disable-next-line */
  }, [])

  return null
}

export const ManageThemePage: React.FC<{ themeOption?: THEME_OPTIONS }> = ({ themeOption }) => {
  const { osContext } = useOs()

  const [searchParams] = useSearchParams()
  const currentTab = searchParams.get('tab') as MANAGE_THEME_SECTIONS

  const [previewBgColor, setPreviewBgColor] = useState('grey-color-000')
  const appContext = useAppContext()
  const navigate = useNavigate()

  const handleTabChange = (event: CustomEvent<TabsChangeEventDetail>) => {
    navigate({
      pathname: `/theme/${themeOption}`,
      search: createSearchParams({
        tab: event.detail.value,
      }).toString(),
    })
  }

  useEffect(() => {
    if (!currentTab) {
      navigate({
        pathname: `/theme/${themeOption}`,
        search: createSearchParams({
          tab: MANAGE_THEME_SECTIONS.COLORS,
        }).toString(),
      })
    }
  }, [currentTab, navigate, themeOption])

  const handleSegmentedControlChange = (event: CustomEvent<SegmentedControlChangeEventDetail>) => {
    setPreviewBgColor(event.detail.value as string)
  }

  const handlePreviewButtonClick = () => {
    appContext.setSavedCreateTheme(appContext.createTheme)
    fetch(`${THEME_BUILDER_API_URL}/files-export/preview`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(getThemeFromCreateThemeInfo(appContext)) as any,
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Failed to load preview')
        }
      })
      .then(json => {
        appContext.setPreviewId(json.id)
        navigate('/theme/preview')
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleFormikSubmit = (values: CreateTheme) => appContext.setCreateTheme(values)

  const initialValues = getCreateThemeInfoFromTheme(
    appContext.jsonData ? (appContext.jsonData!.content!.light as ThemeStyles) : osContext.theme,
  )

  const previewBgColorStyle: Record<string, string> = {
    '--preview-bg-color': `var(--wpp-${previewBgColor})`,
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleFormikSubmit}>
      <form className={styles.createThemePage}>
        <AutoFillSavedCreateTheme />
        <div className={styles.header}>
          {themeOption === THEME_OPTIONS.create ? (
            <WppTypography type="3xl-heading">Create {appContext.createTheme.name} Theme</WppTypography>
          ) : (
            <WppTypography type="3xl-heading">
              Edit {appContext.createTheme.name || osContext.tenant.name} Theme
            </WppTypography>
          )}
          <div className={styles.actions}>
            <WppButton variant="secondary" className={styles.previewButton} onClick={handlePreviewButtonClick}>
              Preview
              <WppIconGallery slot="icon-start" />
            </WppButton>
            <ExportAsButton />
          </div>
        </div>
        <div className={styles.tabsWrapper}>
          <WppTabs value={currentTab} onWppChange={handleTabChange} className={styles.tabs} size="s">
            <WppTab value="colors">Colors</WppTab>
            <WppTab value="typography">Typography</WppTab>
            <WppTab value="object-styles">Object Styles</WppTab>
          </WppTabs>
          <WppDivider color="var(--wpp-grey-color-300)" className={styles.divider} />
        </div>
        <div className={styles.themeAndPreviewSection}>
          <div className={styles.themeSection}>
            {
              {
                colors: <ColorsSection />,
                typography: <TypographySection />,
                'object-styles': <ObjectStylesSection />,
              }[currentTab]
            }
          </div>
          <WppCard size="l" className={styles.componentsPreviewSection} style={previewBgColorStyle}>
            <div className={styles.cardHeader} slot="header">
              <WppTypography type="l-strong">Preview</WppTypography>
              <WppSegmentedControl value={previewBgColor} onWppChange={handleSegmentedControlChange}>
                <WppSegmentedControlItem value="grey-color-000">Grey-000</WppSegmentedControlItem>
                <WppSegmentedControlItem value="grey-color-100">Grey-100</WppSegmentedControlItem>
              </WppSegmentedControl>
            </div>
            <div>
              {
                {
                  colors: <ColorsPreview />,
                  typography: <TypographyPreview />,
                  'object-styles': <ObjectStylesPreview />,
                }[currentTab]
              }
            </div>
          </WppCard>
        </div>
        <UsageDetailsModal />
      </form>
    </Formik>
  )
}
